import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import { Helmet } from 'react-helmet-async';
import Iconify from '../components/iconify';
import { useAuth } from '../hooks/useAuth';
import { URL_GET_LANGSETS, URL_GET_LANGSETS_EDIT } from '../config';
import { CheckSpaces } from '../utils/utilsFunction';
import { useSnackbar } from '../hooks/useSnackbar';

export default function LangSetsEdit() {
  const { showSnackbar } = useSnackbar();
  const { user, signout } = useAuth();
  const [isValidTranslit, setIsValidTranslit] = useState({ isValid: true, errorText: '' });
  const [loading, setLoading] = useState(false);
  const [translit, setTranslit] = useState('');
  const [ua, setUa] = useState('');
  const [pl, setPl] = useState('');
  const [en, setEn] = useState('');
  const [ru, setRu] = useState('');
  const [editText, setEditText] = useState(false);
  const { id } = useParams(); // Параметр з роутингу

  // Змінити поле
  const handleClickEditText = () => {
    setEditText((edit) => !edit);
    showSnackbar({
      message: 'Не рекомендується редагувати дане поле!',
      severity: 'error',
    });
  };
  const handleMouseDownEditText = (event) => {
    event.preventDefault();
  };

  // Щоб зробити крок назад
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  // Відправка форми
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!CheckSpaces(translit) || translit === '') {
      setIsValidTranslit({ isValid: false, errorText: "Tранслітерація обов'язкове поле!" });
      return;
      // eslint-disable-next-line no-else-return
    } else {
      setIsValidTranslit({ isValid: true, errorText: '' });
    }

    setLoading(true);

    const data = {
      id: user.dataUser.id,
      idRow: id,
      translit,
      ua,
      pl,
      en,
      ru,
    };

    fetch(URL_GET_LANGSETS_EDIT, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.ok === 1 && json.status === 200) {
          const { name, ua, pl, en, ru } = json.langsets;
          setTranslit(name);
          setUa(ua);
          setPl(pl);
          setEn(en);
          setRu(ru);

          showSnackbar({
            message: 'Дані успішно збережені!',
          });
        } else {
          showSnackbar({
            message: 'Помилка збереження!',
            severity: 'error',
          });
        }
      })
      .catch((error) => {
        showSnackbar({
          message: error.message,
          severity: 'error',
        });
      })
      .finally(navigate('../..', { relative: 'path' }));
  };

  useEffect(() => {
    setLoading(true);
    fetch(`${URL_GET_LANGSETS}?id=${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.ok === 1 && json.status === 200) {
          const { name, ua, pl, en, ru } = json.langsets;
          setTranslit(name);
          setUa(ua);
          setPl(pl);
          setEn(en);
          setRu(ru);
        } else {
          // Вийти якщо не той результат
          signout(() => navigate('/login', { replace: true }));
        }

        setLoading(false);
      })
      .catch((error) => {
        showSnackbar({
          message: error.message,
          severity: 'error',
        });
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box component="form" id="formEditUser" noValidate autoComplete="off">
        <Helmet>
          <title> Редагувати мовний набір </title>
        </Helmet>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Редагувати мовний набір
            </Typography>
          </Stack>

          <Stack>
            <Box
              sx={{
                '& > :not(style)': { ml: 1, mb: 1 },
                display: 'flex',
                justifyContent: 'center',
                mb: 3,
              }}
            >
              <Button
                variant="contained"
                startIcon={
                  loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <Iconify icon="eva:save-outline" />
                }
                onClick={handleSubmit}
              >
                Зберегти
              </Button>

              <Button onClick={goBack} variant="outlined" startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Відмінити
              </Button>
            </Box>
          </Stack>

          {/* <Box
            sx={{
              '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
            }}
          >
            <TextField
              label="Tранслітерація"
              variant="outlined"
              value={translit}
              onChange={(e) => setTranslit(e.target.value)}
              error={!isValidTranslit.isValid}
              helperText={!isValidTranslit.isValid ? isValidTranslit.errorText : ''}
            />
          </Box> */}

          <Box
            sx={{
              '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
            }}
          >
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Tранслітерація</InputLabel>
              <OutlinedInput
                required
                value={translit}
                onChange={(e) => setTranslit(e.target.value)}
                error={!isValidTranslit.isValid}
                helperText={!isValidTranslit.isValid ? isValidTranslit.errorText : ''}
                id="outlined-adornment-password"
                disabled={editText ? '' : 'disabled'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickEditText}
                      onMouseDown={handleMouseDownEditText}
                      edge="end"
                    >
                      {editText ? <EditIcon /> : <EditOffIcon />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Tранслітерація"
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '50%', md: '50%' } },
            }}
          >
            <TextField
              multiline
              maxRows={4}
              label="UA"
              variant="outlined"
              value={ua}
              onChange={(e) => setUa(e.target.value)}
            />
            <TextField
              multiline
              maxRows={4}
              label="PL"
              variant="outlined"
              value={pl}
              onChange={(e) => setPl(e.target.value)}
            />
            <TextField
              multiline
              maxRows={4}
              label="EN"
              variant="outlined"
              value={en}
              onChange={(e) => setEn(e.target.value)}
            />
            <TextField
              multiline
              maxRows={4}
              label="RU"
              variant="outlined"
              value={ru}
              onChange={(e) => setRu(e.target.value)}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
}
