// import sha1 from 'sha1';
import { MuiFileInput } from 'mui-file-input';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Stack,
  Button,
  Typography,
  TextField,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CardMedia,
  Card,
  CircularProgress,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Iconify from '../components/iconify';
import { PATH_USER_PHOTO, URL_UPDATE_USER, URL_UPDATE_USER_PHOTO } from '../config';
import { useAuth } from '../hooks/useAuth';
import account from '../_mock/account';
import { useSnackbar } from '../hooks/useSnackbar';

export default function Profile() {
  const { showSnackbar } = useSnackbar();
  const { user, signout, setUser } = useAuth();
  const [myInfo, setMyInfo] = useState(account);
  const [isValidEmail, setIsValidEmail] = useState({ isValid: true, errorText: '' });
  const [isValidName, setIsValidName] = useState({ isValid: true, errorText: '' });
  // const [dataHash, setDataHash] = useState('');
  // Прогрес збереження
  const [loading, setLoading] = useState(false);

  // console.log(myInfo);

  // Інтпут типу додати файл
  const [file, setFile] = useState(null);

  // console.log(file);
  const handlePhoto = (newFile) => {
    setFile(newFile);
  };

  // Текстові поля
  const handleText = (event, field) => {
    setMyInfo((previousState) => ({ ...previousState, [field]: event.target.value }));
  };

  // Чекбокс
  const handleConfirmed = (event) => {
    setMyInfo((previousState) => ({ ...previousState, confirmed: event.target.checked }));
  };

  // Щоб зробити крок назад
  const navigate = useNavigate();
  // const goBack = () => navigate(-1);

  // Відправка форми
  const handleSubmit = (event) => {
    event.preventDefault();

    // Отримати дані форми
    if (myInfo.name === '') {
      setIsValidName({ isValid: false, errorText: "Ім'я обов'язкове!" });
      return;
      // eslint-disable-next-line no-else-return
    } else {
      setIsValidName({ isValid: true, errorText: '' });
    }

    if (myInfo.email === '') {
      setIsValidEmail({ isValid: false, errorText: "Електронна пошта обов'язкова!" });
      return;
      // eslint-disable-next-line no-else-return
    } else {
      setIsValidEmail({ isValid: true, errorText: '' });
    }

    // Покажемо прогрес
    setLoading(true);

    // Створити з них хеш
    // setDataHash(sha1(JSON.stringify(myInfo)));

    const formData = new FormData();
    formData.append('photoURL', file);
    formData.append('id', myInfo.id);

    Promise.all([
      fetch(URL_UPDATE_USER, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify(myInfo),
      }).then((response) => response.json()),
      fetch(URL_UPDATE_USER_PHOTO, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
        body: formData,
      }).then((response) => response.json()),
    ])
      .then((response) => {
        // console.log(response);

        let fullResult = {};

        // Якщо першйи запит не пройшов валідацію, то і наступні також
        if (response[0].ok === 1) {
          // попрацюємо з першим масивом
          if (response[0].ok === 1) {
            let isCorrectData = true;

            response[0].user.photoURL = PATH_USER_PHOTO + response[0].user.photoURL;
            response[0].user.photo_medium = PATH_USER_PHOTO + response[0].user.photo_medium;
            response[0].user.photo_small = PATH_USER_PHOTO + response[0].user.photo_small;

            response[0].user.confirmed = response[0].user.confirmed === 1;

            // Наскільки зрозмів, то це перебір ключів, щоб бути певних, що всі ключі на місці
            Object.keys(myInfo).forEach((key) => {
              if (myInfo[key] !== response[0].user[key]) {
                isCorrectData = false;
                console.log('UserEdit які дані відрізняються: ', key, myInfo[key], response[0].user[key]);
                // Щоб вирішити - "а нам потрібно true or false"
                if (key === 'confirmed' && myInfo[key] === true && response[0].user[key] === 1) {
                  isCorrectData = true;
                } else if (key === 'confirmed' && myInfo[key] === false && response[0].user[key] === 0) {
                  isCorrectData = true;
                }
              }
            });

            if (isCorrectData) {
              // setUser(() => ({ ...user, dataUser: response[0].user }));
              fullResult = { ...response[0].user };
            } else {
              console.log('Помилка збереження, перевірте ключі!');
              showSnackbar({
                message: 'Помилка збереження!',
                severity: 'error',
              });
            }
          }

          // попрацюємо з другим масивом
          if (response[1].ok === 1) {
            response[1].user.photoURL = PATH_USER_PHOTO + response[1].user.photoURL;
            response[1].user.photo_medium = PATH_USER_PHOTO + response[1].user.photo_medium;
            response[1].user.photo_small = PATH_USER_PHOTO + response[1].user.photo_small;

            fullResult = { ...fullResult, ...response[1].user };
            setFile(null);
          }

          console.log(fullResult);

          setUser({ ...user, dataUser: { ...fullResult } });

          showSnackbar({
            message: 'Дані успішно збережені!',
          });
          setLoading(false);
        } else {
          // Вийти
          signout(() => navigate('/login', { replace: true }));
        }
      })
      .catch((error) => {
        // Помилка
        console.error(error);
        showSnackbar({
          message: error.message,
          severity: 'error',
        });

        setLoading(false);
      });
  };

  // console.log('User edid var user: ', user);
  // console.log('dataHash: ', dataHash);

  // Перший рендер
  // По суті, ці дані можна взяти з AuthProvider, але проблема з роутингом, при вході на цю сторінку першою не підвантажується дані
  useEffect(() => {
    delete user.dataUser.ur_name;
    delete user.dataUser.role;
    setMyInfo(user.dataUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Box component="form" id="formEditUser" noValidate autoComplete="off">
      <Helmet>
        <title> Редагуємо дані </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Редагуємо дані
          </Typography>
        </Stack>

        <Stack>
          <Box
            sx={{
              '& > :not(style)': { ml: 1, mb: 1 },
              display: 'flex',
              justifyContent: 'center',
              mb: 3,
            }}
          >
            <Button
              variant="contained"
              startIcon={
                loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <Iconify icon="eva:save-outline" />
              }
              onClick={handleSubmit}
            >
              Зберегти
            </Button>

            <Button
              onClick={() => navigate('/dashboard/app')}
              variant="outlined"
              startIcon={<Iconify icon="eva:close-fill" />}
            >
              Закрити
            </Button>
          </Box>
        </Stack>

        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <input type="hidden" id="tf_id" value={myInfo.id} />
          <TextField
            id="tf_surname"
            label="Прізвище"
            variant="outlined"
            value={myInfo.surname}
            onChange={(e) => handleText(e, 'surname')}
          />
          <TextField
            id="tf_name"
            label="Ім'я"
            variant="outlined"
            value={myInfo.name}
            onChange={(e) => handleText(e, 'name')}
            error={!isValidName.isValid}
            helperText={!isValidName.isValid ? isValidName.errorText : ''}
          />
          <TextField
            id="tf_fatherName"
            label="По батькові"
            variant="outlined"
            value={myInfo.fatherName}
            onChange={(e) => handleText(e, 'fatherName')}
          />
        </Box>
        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <TextField
            id="tf_phone"
            label="Номер телефону"
            variant="outlined"
            value={myInfo.phone}
            onChange={(e) => handleText(e, 'phone')}
          />
          <TextField
            id="tf_email"
            label="Електронна пошта"
            variant="outlined"
            value={myInfo.email}
            onChange={(e) => handleText(e, 'email')}
            error={!isValidEmail.isValid}
            helperText={!isValidEmail.isValid ? isValidEmail.errorText : ''}
          />
        </Box>
        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <TextField
            id="tf_company"
            label="Компанія"
            variant="outlined"
            value={myInfo.company}
            onChange={(e) => handleText(e, 'company')}
          />
          <TextField
            id="tf_profession"
            label="Професія"
            variant="outlined"
            value={myInfo.profession}
            onChange={(e) => handleText(e, 'profession')}
          />
        </Box>
        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="confirmed"
                  checked={myInfo.confirmed}
                  onChange={handleConfirmed}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Підтверджено"
            />
          </FormGroup>
        </Box>
        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <MuiFileInput
            id="photoURL"
            label="Вибрати фото"
            variant="outlined"
            value={file}
            onChange={handlePhoto}
            getInputText={(value) => (value ? 'Файл вибраний' : '')}
            accept="image/*,.png,.jpg"
          />
        </Box>
        <Card sx={{ maxWidth: 128 }}>
          <CardMedia component="img" alt="" height="128" image={myInfo.photoURL} />
        </Card>
      </Container>
    </Box>
  );
}
