import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Container, Stack, TextField, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Iconify from '../components/iconify';
import { useAuth } from '../hooks/useAuth';
import { URL_GET_LANGSETS_ADD } from '../config';
import { CheckSpaces } from '../utils/utilsFunction';
import { useSnackbar } from '../hooks/useSnackbar';

export default function LangSetsAdd() {
  const { showSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [isValidTranslit, setIsValidTranslit] = useState({ isValid: true, errorText: '' });
  const [loading, setLoading] = useState(false);
  const [translit, setTranslit] = useState('');
  const [ua, setUa] = useState('');
  const [pl, setPl] = useState('');
  const [en, setEn] = useState('');
  const [ru, setRu] = useState('');

  // Щоб зробити крок назад
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  // Відправка форми
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!CheckSpaces(translit) || translit === '') {
      setIsValidTranslit({ isValid: false, errorText: "Tранслітерація обов'язкове поле!" });
      return;
      // eslint-disable-next-line no-else-return
    } else {
      setIsValidTranslit({ isValid: true, errorText: '' });
    }

    try {
      setLoading(true);

      const data = {
        id: user.dataUser.id,
        translit,
        ua,
        pl,
        en,
        ru,
      };

      // надсилаємо асинхронним запитом
      const res = await fetch(URL_GET_LANGSETS_ADD, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify(data),
      });
      const result = await res.json();

      if (result.ok === 1 && result.status === 201) {
        showSnackbar({
          message: 'Дані успішно збережені!',
        });
      } else {
        showSnackbar({
          message: 'Помилка збереження!',
          severity: 'error',
        });
      }
    } catch (error) {
      showSnackbar({
        message: error.message,
        severity: 'error',
      });
    } finally {
      setLoading(false);
      navigate('..', { relative: 'path' });
    }
  };

  return (
    <>
      <Box component="form" id="formEditUser" noValidate autoComplete="off">
        <Helmet>
          <title> Додати мовний набір </title>
        </Helmet>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Додати мовний набір
            </Typography>
          </Stack>

          <Stack>
            <Box
              sx={{
                '& > :not(style)': { ml: 1, mb: 1 },
                display: 'flex',
                justifyContent: 'center',
                mb: 3,
              }}
            >
              <Button
                variant="contained"
                startIcon={
                  loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <Iconify icon="eva:save-outline" />
                }
                onClick={handleSubmit}
              >
                Зберегти
              </Button>

              <Button onClick={goBack} variant="outlined" startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Відмінити
              </Button>
            </Box>
          </Stack>

          <Box
            sx={{
              '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
            }}
          >
            <TextField
              required
              label="Tранслітерація"
              variant="outlined"
              value={translit}
              onChange={(e) => setTranslit(e.target.value)}
              error={!isValidTranslit.isValid}
              helperText={!isValidTranslit.isValid ? isValidTranslit.errorText : ''}
            />
          </Box>
          <Box
            sx={{
              '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '50%', md: '50%' } },
            }}
          >
            <TextField
              multiline
              maxRows={4}
              label="UA"
              variant="outlined"
              value={ua}
              onChange={(e) => setUa(e.target.value)}
            />
            <TextField
              multiline
              maxRows={4}
              label="PL"
              variant="outlined"
              value={pl}
              onChange={(e) => setPl(e.target.value)}
            />
            <TextField
              multiline
              maxRows={4}
              label="EN"
              variant="outlined"
              value={en}
              onChange={(e) => setEn(e.target.value)}
            />
            <TextField
              multiline
              maxRows={4}
              label="RU"
              variant="outlined"
              value={ru}
              onChange={(e) => setRu(e.target.value)}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
}
