import { MuiFileInput } from 'mui-file-input';
import {
  Box,
  Container,
  Stack,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Tab,
  Card,
  CardMedia,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import settings from '../_mock/settings';
import Iconify from '../components/iconify';
import { useAuth } from '../hooks/useAuth';
import { PATH_LOGO_PHOTO, URL_GET_SETTINGS, URL_UPDATE_SETTINGS, URL_UPDATE_SETTINGS_PHOTO } from '../config';
import { useSnackbar } from '../hooks/useSnackbar';

export default function Settings() {
  const { showSnackbar } = useSnackbar();
  const { user, signout } = useAuth();
  const [name, setName] = useState(settings.name);
  const [year, setYear] = useState(settings.year);
  const [logo, setLogo] = useState(settings.logo);
  const [logoLight, setLogoLight] = useState(settings.logoLight);
  const [titleUa, setTitleUa] = useState(settings.titleUa);
  const [descriptionUa, setDescriptionUa] = useState(settings.descriptionUa);
  const [keywordsUa, setKeywordsUa] = useState(settings.keywordsUa);
  const [titlePl, setTitlePl] = useState(settings.titlePl);
  const [descriptionPl, setDescriptionPl] = useState(settings.descriptionPl);
  const [keywordsPl, setKeywordsPl] = useState(settings.keywordsPl);
  const [titleEn, setTitleEn] = useState(settings.titleEn);
  const [descriptionEn, setDescriptionEn] = useState(settings.descriptionEn);
  const [keywordsEn, setKeywordsEn] = useState(settings.keywordsEn);
  const [titleRu, setTitleRu] = useState(settings.titleRu);
  const [descriptionRu, setDescriptionRu] = useState(settings.descriptionRu);
  const [keywordsRu, setKeywordsRu] = useState(settings.keywordsRu);
  const [viewUa, setViewUa] = useState(settings.viewUa);
  const [viewPl, setViewPl] = useState(settings.viewPl);
  const [viewEn, setViewEn] = useState(settings.viewEn);
  const [viewRu, setViewRu] = useState(settings.viewRu);
  const [loading, setLoading] = useState(false);
  // Tabs
  const [value, setValue] = useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Інтпут типу додати файл
  const [file, setFile] = useState(null);
  const [fileLigth, setFileLigth] = useState(null);

  // console.log(file);
  const handlePhoto = (newFile) => {
    setFile(newFile);
  };
  const handlePhotoLight = (newFile) => {
    setFileLigth(newFile);
  };

  // Щоб зробити крок назад
  const navigate = useNavigate();
  // const goBack = () => navigate(-1);

  // Для відтворення збережених даних
  const setData = (data) => {
    const {
      name,
      year,
      titleUa,
      descriptionUa,
      keywordsUa,
      titlePl,
      descriptionPl,
      keywordsPl,
      titleEn,
      descriptionEn,
      keywordsEn,
      titleRu,
      descriptionRu,
      keywordsRu,
      viewUa,
      viewPl,
      viewEn,
      viewRu,
    } = data;
    setName(name);
    setYear(year);
    setTitleUa(titleUa);
    setDescriptionUa(descriptionUa);
    setKeywordsUa(keywordsUa);
    setTitlePl(titlePl);
    setDescriptionPl(descriptionPl);
    setKeywordsPl(keywordsPl);
    setTitleEn(titleEn);
    setDescriptionEn(descriptionEn);
    setKeywordsEn(keywordsEn);
    setTitleRu(titleRu);
    setDescriptionRu(descriptionRu);
    setKeywordsRu(keywordsRu);
    setViewUa(viewUa);
    setViewPl(viewPl);
    setViewEn(viewEn);
    setViewRu(viewRu);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // const form = document.getElementById('formSettings');

    // Покажемо прогрес
    setLoading(true);

    // Зображення тут не приймаємо до уваги
    const data = {
      id: user.dataUser.id,
      name,
      year,
      titleUa,
      descriptionUa,
      keywordsUa,
      titlePl,
      descriptionPl,
      keywordsPl,
      titleEn,
      descriptionEn,
      keywordsEn,
      titleRu,
      descriptionRu,
      keywordsRu,
      viewUa,
      viewPl,
      viewEn,
      viewRu,
    };

    // console.log('user: ', user);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('field', 'logoName');
    formData.append('id', user.dataUser.id);

    const formDataLight = new FormData();
    formDataLight.append('file', fileLigth);
    formDataLight.append('field', 'logoNameLight');
    formDataLight.append('id', user.dataUser.id);

    Promise.all([
      fetch(URL_UPDATE_SETTINGS, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify(data),
      }).then((response) => response.json()),
      fetch(URL_UPDATE_SETTINGS_PHOTO, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
        body: formData,
      }).then((response) => response.json()),
      fetch(URL_UPDATE_SETTINGS_PHOTO, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
        body: formDataLight,
      }).then((response) => response.json()),
    ])
      .then((response) => {
        // console.log(response);

        // Якщо першйи запит не пройшов валідацію, то і наступні також
        if (response[0].ok === 1) {
          // Перша відповідь
          if (response[0].ok === 1) {
            // Зображення тут не приймаємо до уваги
            const { settings } = response[0];
            // console.log('settings: ', settings);

            if (settings.viewUa) {
              settings.viewUa = true;
            } else {
              settings.viewUa = false;
            }
            if (settings.viewPl) {
              settings.viewPl = true;
            } else {
              settings.viewPl = false;
            }
            if (settings.viewEn) {
              settings.viewEn = true;
            } else {
              settings.viewEn = false;
            }
            if (settings.viewRu) {
              settings.viewRu = true;
            } else {
              settings.viewRu = false;
            }

            setData(settings);
          }

          // LOGO 1
          if (response[1].ok === 1) {
            setLogo(PATH_LOGO_PHOTO + response[1].settings.logoName);
            setFile(null);
          }

          // LOGO 2
          if (response[2].ok === 1) {
            setLogoLight(PATH_LOGO_PHOTO + response[2].settings.logoNameLight);
            setFileLigth(null);
          }

          showSnackbar({
            message: 'Дані успішно збережені!',
          });

          setLoading(false);
        } else {
          signout(() => navigate('/login', { replace: true }));
        }
      })
      .catch((error) => {
        // Помилка
        console.error(error);
        showSnackbar({
          message: 'Помилка збереження!',
          severity: 'error',
        });

        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);

    // URL_GET_SETTINGS
    fetch(URL_GET_SETTINGS, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log('Settings useEffect: ', json, json.settings);

        if (json.ok === 1 && json.status === 200) {
          // Додамо шлях до назви файлу
          // json.settings.logoName = PATH_LOGO_PHOTO + json.settings.logoName;
          // json.settings.logoNameLight = PATH_LOGO_PHOTO + json.settings.logoNameLight;

          // console.log('useEffect: ', json.settings);

          if (json.settings.viewUa) {
            json.settings.viewUa = true;
          } else {
            json.settings.viewUa = false;
          }
          if (json.settings.viewPl) {
            json.settings.viewPl = true;
          } else {
            json.settings.viewPl = false;
          }
          if (json.settings.viewEn) {
            json.settings.viewEn = true;
          } else {
            json.settings.viewEn = false;
          }
          if (json.settings.viewRu) {
            json.settings.viewRu = true;
          } else {
            json.settings.viewRu = false;
          }

          setData(json.settings);
          setLogo(PATH_LOGO_PHOTO + json.settings.logoName);
          setLogoLight(PATH_LOGO_PHOTO + json.settings.logoNameLight);

          setLoading(false);
        } else {
          // На сторінку входу
          signout(() => navigate('/login', { replace: true }));
        }
      })
      .catch((error) => {
        console.error(error);
        showSnackbar({
          message: error.message,
          severity: 'error',
        });

        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component="form" id="formSettings" noValidate autoComplete="off">
      <Helmet>
        <title>Налаштування</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Налаштування
          </Typography>
        </Stack>
        <Stack>
          <Box
            sx={{
              '& > :not(style)': { ml: 1, mb: 1 },
              display: 'flex',
              justifyContent: 'center',
              mb: 3,
            }}
          >
            <Button
              variant="contained"
              startIcon={
                loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <Iconify icon="eva:save-outline" />
              }
              onClick={handleSubmit}
            >
              Зберегти
            </Button>

            <Button
              onClick={() => navigate('/dashboard/app')}
              variant="outlined"
              startIcon={<Iconify icon="eva:close-fill" />}
            >
              Закрити
            </Button>
          </Box>
        </Stack>

        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <TextField
            id="s_name"
            label="Назва сайту"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>

        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: '15ch' },
          }}
        >
          <TextField
            id="s_year"
            label="Рік заснування"
            variant="outlined"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            type="number"
          />
        </Box>

        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {/* Лого, темне лого */}
          <Box
            sx={{
              '& > :not(style)': { my: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
            }}
          >
            <MuiFileInput
              id="photoURL"
              label="Вибрати темне лого"
              variant="outlined"
              value={file}
              onChange={handlePhoto}
              getInputText={(value) => (value ? 'Файл вибраний' : '')}
            />
            <Card sx={{ maxWidth: 128, borderRadius: 0 }}>
              <CardMedia component="img" alt={logo} image={logo} />
            </Card>
          </Box>
          {/* Лого, світле лого */}
          <Box
            sx={{
              '& > :not(style)': { my: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
            }}
          >
            <MuiFileInput
              id="photoURL_ligth"
              label="Вибрати світле лого"
              variant="outlined"
              value={fileLigth}
              onChange={handlePhotoLight}
              getInputText={(value) => (value ? 'Файл вибраний' : '')}
            />
            <Card sx={{ maxWidth: 128, borderRadius: 0 }}>
              <CardMedia component="img" alt={logoLight} image={logoLight} />
            </Card>
          </Box>
        </Box>

        {/* title, description and keywords */}
        {/* https://mui.com/material-ui/react-tabs/#experimental-api */}
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="lab API tabs example"
              >
                <Tab label="UA" value="1" />
                <Tab label="PL" value="2" />
                <Tab label="EN" value="3" />
                <Tab label="RU" value="4" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box
                sx={{
                  '& > :not(style)': { mb: 2, mr: 2, width: '100%' },
                }}
              >
                <TextField
                  multiline
                  rows={1}
                  id="s_keywordsUa"
                  label="Назва мови"
                  variant="outlined"
                  value={keywordsUa}
                  onChange={(e) => setKeywordsUa(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  '& > :not(style)': { mb: 2, mr: 2, width: '100%' },
                }}
              >
                <TextField
                  id="s_titleUa"
                  label="title сторінки"
                  variant="outlined"
                  value={titleUa}
                  onChange={(e) => setTitleUa(e.target.value)}
                  helperText="Назва сторінки, оптимальна довжина 50-60 символів"
                />
              </Box>
              <Box
                sx={{
                  '& > :not(style)': { mb: 2, mr: 2, width: '100%' },
                }}
              >
                <TextField
                  multiline
                  rows={3}
                  id="s_descriptionUa"
                  label="description сторінки"
                  variant="outlined"
                  value={descriptionUa}
                  onChange={(e) => setDescriptionUa(e.target.value)}
                  helperText="Короткий опис сторінки, зазвичай 160-180 символів"
                />
              </Box>
              <Box
                sx={{
                  '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="confirmedUa"
                        checked={viewUa}
                        onChange={(e) => setViewUa(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Показувати мову"
                  />
                </FormGroup>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Box
                sx={{
                  '& > :not(style)': { mb: 2, mr: 2, width: '100%' },
                }}
              >
                <TextField
                  multiline
                  rows={1}
                  id="s_keywordsPl"
                  label="Назва мови Pl"
                  variant="outlined"
                  value={keywordsPl}
                  onChange={(e) => setKeywordsPl(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  '& > :not(style)': { mb: 2, mr: 2, width: '100%' },
                }}
              >
                <TextField
                  id="s_titlePl"
                  label="title Pl"
                  variant="outlined"
                  value={titlePl}
                  onChange={(e) => setTitlePl(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  '& > :not(style)': { mb: 2, mr: 2, width: '100%' },
                }}
              >
                <TextField
                  multiline
                  rows={3}
                  id="s_descriptionPl"
                  label="description Pl"
                  variant="outlined"
                  value={descriptionPl}
                  onChange={(e) => setDescriptionPl(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="confirmedPl"
                        checked={viewPl}
                        onChange={(e) => setViewPl(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Показувати мову"
                  />
                </FormGroup>
              </Box>
            </TabPanel>
            <TabPanel value="3">
              <Box
                sx={{
                  '& > :not(style)': { mb: 2, mr: 2, width: '100%' },
                }}
              >
                <TextField
                  multiline
                  rows={1}
                  id="s_keywordsEn"
                  label="Назва мови En"
                  variant="outlined"
                  value={keywordsEn}
                  onChange={(e) => setKeywordsEn(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  '& > :not(style)': { mb: 2, mr: 2, width: '100%' },
                }}
              >
                <TextField
                  id="s_titleEn"
                  label="title En"
                  variant="outlined"
                  value={titleEn}
                  onChange={(e) => setTitleEn(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  '& > :not(style)': { mb: 2, mr: 2, width: '100%' },
                }}
              >
                <TextField
                  multiline
                  rows={3}
                  id="s_descriptionEn"
                  label="description En"
                  variant="outlined"
                  value={descriptionEn}
                  onChange={(e) => setDescriptionEn(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="confirmedEn"
                        checked={viewEn}
                        onChange={(e) => setViewEn(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Показувати мову"
                  />
                </FormGroup>
              </Box>
            </TabPanel>
            <TabPanel value="4">
              <Box
                sx={{
                  '& > :not(style)': { mb: 2, mr: 2, width: '100%' },
                }}
              >
                <TextField
                  multiline
                  rows={1}
                  id="s_keywordsRu"
                  label="Назва мови Ru"
                  variant="outlined"
                  value={keywordsRu}
                  onChange={(e) => setKeywordsRu(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  '& > :not(style)': { mb: 2, mr: 2, width: '100%' },
                }}
              >
                <TextField
                  id="s_titleRu"
                  label="title Ru"
                  variant="outlined"
                  value={titleRu}
                  onChange={(e) => setTitleRu(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  '& > :not(style)': { mb: 2, mr: 2, width: '100%' },
                }}
              >
                <TextField
                  multiline
                  rows={3}
                  id="s_descriptionRu"
                  label="description Ru"
                  variant="outlined"
                  value={descriptionRu}
                  onChange={(e) => setDescriptionRu(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="confirmedRu"
                        checked={viewRu}
                        onChange={(e) => setViewRu(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Показувати мову"
                  />
                </FormGroup>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </Box>
  );
}
