import PropTypes from 'prop-types';
import { createContext, useState } from 'react';
import { PATH_USER_PHOTO, URL_GET_USER } from '../config';

// Створили контекст
export const AuthContext = createContext(null);

// Обернули провайдер
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const signin = (newUser, cb) => {
    setUser(newUser);
    // console.log('newUser: ', newUser);
    // при вході визвати також запит всієї інформації для додатка
    getUserDetail(newUser);
    cb();
  };

  const signout = (cb) => {
    setUser(null);
    cb();
  };

  // Запросити повну інформацію для всього додатку
  const getUserDetail = (newUser) => {
    fetch(URL_GET_USER, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${newUser?.token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log('AuthProvider getUserDetail 1: ', json.user);
        // Змінимо трохи об'єкт, додамо PATH_USER_PHOTO до наших назв
        json.user.photoURL = PATH_USER_PHOTO + json.user.photoURL;
        json.user.photo_medium = PATH_USER_PHOTO + json.user.photo_medium;
        json.user.photo_small = PATH_USER_PHOTO + json.user.photo_small;
        // Додамо роль, для nav
        json.user.role = json.user.ur_name;
        // confirmed має бути true or false
        // https://stackoverflow.com/questions/49971321/unnecessary-use-of-boolean-literals-in-conditional-expression
        json.user.confirmed = json.user.confirmed === 1;
        // console.log('AuthProvider getUserDetail 2: ', json);
        setUser({
          ...newUser,
          dataUser: json.user,
        });
      })
      .catch((error) => console.error(error));
  };

  console.log('AuthProvider user: ', user);

  // ці три параметри об'єкта будуть доступні в любому компоненті
  // їх можна отримати через хук useContext
  // напр. const { user, signout } = useAuth();
  const value = { user, signin, signout, setUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// https://www.npmjs.com/package/prop-types
AuthProvider.propTypes = {
  children: PropTypes.node,
};
