const BASE_URL = 'https://admin-api.vyhivskyi.com/';
export const URL_LOGIN = `${BASE_URL}login.php`;
export const URL_REGISTER = `${BASE_URL}register.php`;
export const URL_GET_USER = `${BASE_URL}getUser.php`;
export const URL_UPDATE_USER = `${BASE_URL}updateUser.php`;
export const URL_GET_SETTINGS = `${BASE_URL}getSettings.php`;
export const URL_UPDATE_SETTINGS = `${BASE_URL}updateSettings.php`;
export const URL_UPDATE_USER_PHOTO = `${BASE_URL}updateUserPhoto.php`;
export const URL_UPDATE_SETTINGS_PHOTO = `${BASE_URL}updateSettingsPhoto.php`;
export const URL_GET_LANGSETS = `${BASE_URL}getLangSets.php`;
export const URL_GET_LANGSETS_ADD = `${BASE_URL}getLangSetsAdd.php`;
export const URL_GET_LANGSETS_EDIT = `${BASE_URL}getLangSetsEdit.php`;
export const URL_GET_LANGSETS_DELETE = `${BASE_URL}getLangSetsDelete.php`;
export const PATH_USER_PHOTO = `${BASE_URL}images/user/`;
export const PATH_LOGO_PHOTO = `${BASE_URL}images/logo/`;
// UsersAdmin
export const PATH_GET_USERS_ADMIN = `${BASE_URL}getUsersAdmin.php`;

// for test:
// Viktor@Viktor.com
// Viktor@Viktor.com
