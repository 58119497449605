import { useState } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
// @mui
import {
  Button,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  CircularProgress,
  Alert,
  Collapse,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { URL_REGISTER } from '../../../config';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const location = useLocation();

  // const fromPage = location.state?.from?.pathname || '/login';
  const fromPage = location.state?.from?.pathname || '/';

  console.log(fromPage);

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [errorText, setErrorText] = useState('Помилка реєстрації!');
  const [infoText, setInfoText] = useState('');
  const [btnDisable, setBtnDisable] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();

    setIsLoading(false);

    const form = event.target;
    const name = form.name.value;
    const user = form.email.value;
    const pass = form.password.value;
    const pass2 = form.password2.value;

    if (name === '') {
      setTimeout(() => {
        setErrorText("Вкажіть ім'я!");
        setIsLoading(true);
        setOpen(true);
      }, 100);
      return;
    }
    if (user === '') {
      setTimeout(() => {
        setErrorText('Вкажіть електронну адресу!');
        setIsLoading(true);
        setOpen(true);
      }, 100);
      return;
    }

    if (pass === '') {
      setTimeout(() => {
        setErrorText('Вкажіть пароль!');
        setIsLoading(true);
        setOpen(true);
      }, 100);
      return;
    }

    if (pass2 === '') {
      setTimeout(() => {
        setErrorText('Повторіть пароль!');
        setIsLoading(true);
        setOpen(true);
      }, 100);
      return;
    }

    if (pass !== pass2) {
      setTimeout(() => {
        setErrorText('Паролі мають співпадати!');
        setIsLoading(true);
        setOpen(true);
      }, 100);
      return;
    }

    const dataNameAndPass = {
      name,
      email: user,
      password: pass,
    };

    fetch(URL_REGISTER, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataNameAndPass),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json?.success === 1) {
          // signin(json, () => navigate(fromPage, { replace: true }));
          // тут показати повідомлення про успішну реєстрацію та попросити перейти на сторінку входу
          // setInfoText('Вітаємо, ви успішно зареєстровані! Перейдіть на сторінку входу, щоб увійти в панель керування!');
          setInfoText(
            'Вітаємо, ви успішно зареєстровані, очікуйте підтвердження адміністратора! Перейдіть на сторінку входу, щоб увійти в панель керування!'
          );
          setIsLoading(true);
          setOpenInfo(true);
          setOpen(false);
          setBtnDisable(true);
        } else {
          setErrorText('Помилка реєстрації!');
          setIsLoading(true);
          setOpen(true);
          setOpenInfo(false);
          setBtnDisable(false);
        }
      })
      .catch((error) =>
        setTimeout(() => {
          setErrorText(error);
          setIsLoading(true);
          setOpen(true);
        }, 1000)
      );
  };

  return (
    <>
      <Box component="form" onSubmit={handleClick}>
        <Stack spacing={3}>
          <TextField name="name" label="Ім'я" />
          <TextField name="email" label="Електронна адреса" />

          <TextField
            name="password"
            label="Пароль"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            name="password2"
            label="Повторіть пароль"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Collapse in={open}>
          <Alert
            sx={{ my: 3 }}
            severity="error"
            onClose={() => {
              setOpen(false);
            }}
          >
            {errorText}
          </Alert>
        </Collapse>

        <Collapse in={openInfo}>
          <Alert
            action={
              <Button
                variant="outlined"
                endIcon={<Iconify icon={'material-symbols:arrow-right-alt'} />}
                to="/login"
                component={RouterLink}
                color="inherit"
                size="small"
              >
                Перейти
              </Button>
            }
            sx={{ my: 3 }}
            severity="success"
          >
            {infoText}
          </Alert>
        </Collapse>

        {/* disabled */}
        <LoadingButton
          disabled={btnDisable ? 'disabled' : ''}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ my: 2 }}
        >
          {isLoading ? `Зареєструватися` : <CircularProgress color={'inherit'} size={'1.5rem'} />}
        </LoadingButton>
      </Box>
    </>
  );
}
